.banner {
  width: 100%;
  line-height: 40px;
  font-size: 1.5em;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background: #f8485e;
  color: #fff;
  text-align: center;
  border: none;
  display: block;
  font-weight: bolder;
  z-index: 1;
  position: relative;

  .close {
    position: relative;
    float: right;
    padding-right: 20px;
    z-index: 2;
  }
}

/* @override http://mura:8500/default/includes/themes/merced/css/site.css */

/*
Theme:  Merced
Author: Blue River Interactive Group
*/

/* @group GLOBALS
===================================== */

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  color: #434343;
  background: url(/assets/img/bg.jpg) top center no-repeat #000;
}

* {
  -moz-outline: 0;
}

hr {
  clear: both;
  height: 0;
  margin: 2em 0;
  border: 1px solid #ccc;
  border-width: 1px 0 0;
}

a {
  color: #f7a861;
}

.sidebar a {
  font-weight: bold !important;
}

a:hover {
  text-decoration: underline;
}

p.intro {
  color: #666;
  line-height: 1.4em;
  margin-bottom: 1.25em;
}

/* @group Globals - Tables
===================================== */

.content table {
  width: 100%;
  margin-top: 30px;
}

th {
  border: 1px solid;
  border-color: #becfd6 #becfd6 #dce6ea;
  color: #32566c;
  background: #e9f3f7;
}

td {
  padding: 10px;
  border: 1px solid #d6d6d6;
}

tr.alt td {
  background: #eee;
} /*Move*/

/* @end Globals - Tables
-------------------------------- */

/* Photos */

#lightgallery {
  a {
    display: inline-block;
    width: 33%;
    margin: 0;
    padding: 10px 0;
    img {
      width: 200px;
      margin: auto;
      display: block;
      transition: 0.5s all;
      -webkit-transition: 0.5s all;

      &:hover {
        -webkit-filter: brightness(70%);
        filter: brightness(70%);
      }
    }
  }
}

/* Events */
#events .event {
  padding-bottom: 25px;
  width: 100%;
}

/* @group Globals - Forms
===================================== */

#primary form {
  padding: 2em;
  background: #e9f3f7;
  overflow: hidden;
  max-width: 504px; /*width of #primary in three column layout*/
}

#primary #svSearchResults form {
  max-width: 100%;
}

#primary fieldset {
  border: none;
  padding: 0;
}

.pageTitle {
  background: #682067;
  color: #fff;
  padding: 5px 10px;
  margin: 0;
  font-size: 22px;
}

#primary legend {
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

#primary label {
  padding-top: 0.5em;
  line-height: 1;
  color: #0c2846;
}

.submit,
.buttons input {
  float: right;
  height: 2em;
  line-height: 2.1em;
  border: none;
  padding: 0 1em;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  background: #2e7aa7;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}

/* @group Globals - Forms - Search
===================================== */

#searchForm {
  position: absolute;
  top: 30%;
  right: 0;
  margin: 0;
  padding: 0;
}

#searchForm fieldset {
  padding: 0;
  border: 0;
}

#searchForm input.text {
  float: left;
  width: 160px;
  margin: 0 0.75em 0 0;
  font-style: italic;
  padding: 1px;
}

#searchForm .submit {
  float: right;
}

/* @end Globals - Forms - Search
-------------------------------- */

/* @group Contact
===================================== */

.left-col {
  width: 50%;
  float: left;
  display: inline-block;
}
.right-col {
  width: 50%;
  float: right;
  display: inline-block;
}

/* @group Globals - Forms - Login
===================================== */

#login {
  padding-bottom: 1em !important;
}

#login ol {
  padding-top: 0;
}

#svLoginContainer input.text {
  width: 99%;
}

#svLoginContainer p.required {
  float: none;
  clear: both;
}

#sendLogin fieldset {
  margin-top: -0.5em;
  padding-top: 0.25em;
}

/* @end Globals - Forms - Login
-------------------------------- */

.sidebar #login {
  padding: 0;
}

/* @end Globals - Forms - Login
-------------------------------- */

/* @group Globals - Forms - Comments
===================================== */

#svComments {
  border-top: 2px solid #ccc;
  margin-top: 3em;
  padding-top: 2em;
}

#svComments dl {
  border-bottom: 1px dotted #ccc;
}

/* @end Globals - Forms - Comments
-------------------------------- */

/* @end Globals - Forms
-------------------------------- */

/* @end GLOBALS
-------------------------------- */

/* @group HELPER CLASSES
===================================== */

.clearfix:after {
  content: " "; /* Avoid extra space */
}

/* @end HELPER CLASSES
-------------------------------- */

/* @group LAYOUT
===================================== */

#container,
#headerContainer,
#footerContainer {
  width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#content {
  position: relative;
  margin-top: 15px;
  min-height: 60vh;
}

.index #content {
  margin-top: 0;
}

#primary {
  margin-top: 50px;
  float: left;
  width: 699px;
  color: #232323;
  background: #fff;
  border: 4px #682067 solid;
}
.contentPadded {
  padding: 25px 20px;
  min-height: 50vh;
}

.pad-1 {
  padding: 1em;
}

.text-center {
  text-align: center;
  img {
    display: block;
    margin: auto;
  }
}

.button {
  width: 100%;
  background: #682067;
  height: 40px;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 1.3em;
  line-height: 40px;
  text-decoration: none;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    background: darken(#682067, 10%);
  }
}

.row:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.twoColSL #primary {
  width: 707px;
}

.twoColSR #primary {
  float: left;
  width: 707px;
}

.threeCol #primary {
  width: 504px;
}

.content,
.sidebar {
  min-height: 100px; /*Fixes odd no-content disappearing bug.*/
}

#left {
  float: left;
  width: 247px;
  margin-right: 26px;
}

.index #left {
  float: left;
  width: 247px;
  margin-right: 26px;
}

#right {
  float: right;
  width: 156px;
  margin: 13px 0 0;
  padding: 16px 13px;
}

.index #right {
  width: 213px;
  margin: -40px 0 0;
  padding: 0;
}

.threeColSR {
  width: 167px;
}

.sidebar {
}
#left.sidebar {
}
#right.sidebar {
}

/* @group Layout - Header
===================================== */

#header {
  position: relative;
  background: url(../assets/img/headerBG.png) repeat-x;
  padding: 10px 0 0 0;
}

#headerContent {
  float: left;
  margin-left: 30px;
  padding-top: 12px;
  color: #f7a861;
}
#headerContent h3 {
  font-size: 28px;
  color: #fff;
  float: left;
}
#headerContent a,
#headerContent a:visited {
  color: #d8c327;
}
h1 a {
  display: block;
  width: 271px;
  height: 272px;
  text-indent: -9999px;
  background: url(/assets/img/logo.png) no-repeat;
  float: left;
}

a.facebook {
  float: right;
  margin-left: 5px;
}

#header .navUtility {
  position: absolute;
  top: 32%;
  right: 225px;
}

/* @end Layout - Header
-------------------------------- */

/* @group Layout - Right
===================================== */

#right {
  background: #f2f2f2;
}

/* @end Layout - Right
-------------------------------- */

/* @group Layout - Footer
===================================== */

#footer {
  clear: both;
  margin-top: 20px;
  height: 26px;
}

#footer p {
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

/* @end Layout - Footer
-------------------------------- */

/* @end LAYOUT
-------------------------------- */

/* @group NAVIGATION/UI
===================================== */

/* @group Nav - Breadcrumb
===================================== */

#crumbList {
  margin: 0;
  font-weight: bold;
  background: #320531;
  padding: 3px 10px;
  color: #ccc;
  /* TRANSPARENCY */
  filter: alpha(opacity=100) !important; /* for IE */
  opacity: 1 !important; /* CSS3 standard */
}

#crumbList li {
  font-size: 1.1em;
}

#crumbList li a {
  margin-left: 5px;
}

#crumbList li a:hover {
  text-decoration: underline;
}

/* @end Nav - Breadcrumb
-------------------------------- */

/* @group Nav - Utility
===================================== */

.navUtility {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.navUtility li {
  display: inline;
  margin: 0 0.5em 0 -0.5em;
  padding: 0 10px;
  list-style: none;
  font-size: 1em !important;
}

#footer .navUtility {
  float: left;
}

#footer .navUtility li {
  padding: 0 5px;
  border-left: 1px solid #cfcfcf;
}

#header .navUtility li {
  border-left: 1px solid #959595;
}

#footer .navUtility li a {
  font-size: 0.9em !important;
  color: #999;
}

/* @end Nav - Primary
-------------------------------- */

/* @group Nav - Primary
===================================== */

#navPrimary,
#navFooter {
  height: 26px;
  overflow: hidden;
  padding: 54px 0 0 0;
  list-style: none;
  font-weight: bold;
  color: #fff;
}

#navFooter {
  padding: 0;
}

#navPrimary li,
#navFooter li {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}

#navPrimary li.first,
#navFooter li.first {
  margin-left: 15px;
}

#navPrimary a,
#navFooter a {
  display: block;
  padding: 0 15px;
  height: 26px;
  line-height: 26px;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
}

#navPrimary li a:hover,
#navPrimary li.current a,
#navPrimary li:hover,
#navPrimary li.sfhover,
#navFooter li a:hover,
#navFooter li.current a,
#navFooter li:hover,
#navFooter li.sfhover {
  background: #000;
}

#navPrimary li:hover a,
#navPrimary li li:hover a:hover,
#navPrimary li.sfhover a:hover {
  color: #fff;
}

#navPrimary li li a:hover {
  color: #fff;
  text-decoration: underline;
}

/* @group Nav - Primary - Dropdown
===================================== */
/* http://www.htmldog.com/articles/suckerfish/dropdowns/ */

#navPrimary li ul {
  /* second-level lists */
  position: absolute;
  left: -9999px;
  z-index: 10;
  width: 12em;
  margin: 0;
  padding-left: 0;
  list-style: none;
  background: #2e7aa7;
}

#navPrimary li li {
  /* all list items */
  float: left;
  width: 12em; /* width needed for Opera */
  margin: 0 !important;
}

#navPrimary li li a {
  display: block;
  padding: 0.5em;
  line-height: 1;
  text-transform: none;
}

#navPrimary ul li.first,
#navPrimary ul li li.first {
  /* all list items */
  border: 0;
}

#navPrimary li ul ul {
  /* third-and-above-level lists */
  margin: -1.95em 0 0 12em;
}

#navPrimary li:hover ul ul,
#navPrimary li:hover ul ul ul,
#navPrimary li.sfhover ul ul,
#navPrimary li.sfhover ul ul ul {
  left: -9999px;
  z-index: 1001;
}

#navPrimary li:hover ul,
#navPrimary li li:hover ul,
#navPrimary li li li:hover ul,
#navPrimary li.sfhover ul,
#navPrimary li li.sfhover ul,
#navPrimary li li li.sfhover ul {
  /* lists nested under hovered list items */
  left: auto;
}

/* @end Nav - Primary - Dropdown
-------------------------------- */

/* @end Nav - Primary
-------------------------------- */

/* @group Nav - Secondary
===================================== */

.navSecondary {
  margin: 0 0 4em;
  padding: 0;
  list-style: none;
  height: auto;
}

.navSecondary li {
  margin: 0 0 0.5em;
  padding: 0 0 0.5em;
  border-bottom: 1px solid #d6d6d6;
  line-height: 1.2;
}

.navSecondary .last {
  border: 0;
  padding-bottom: 0;
}

.navSecondary li ul {
  margin: 0.5em 0 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.navSecondary li {
  margin-bottom: 0.5em;
}

.navSecondary li a {
  display: block;
}
.navSecondary li a:hover {
}

.navSecondary li.current a {
  font-weight: bold;
  color: #ff3405;
}

/* @group Nav - Secondary - Second Level
===================================== */

.navSecondary li.current ul {
}

.navSecondary li li {
  margin: 0 0 0.5em 1em;
  padding: 0;
  border-bottom: none;
  list-style: none;
  line-height: 1.2em;
}

.navSecondary li.current li a {
  font-weight: normal;
  color: #434343;
}

/* @end Nav - Secondary - Second Level
-------------------------------- */

/* @end Nav - Secondary
-------------------------------- */

/* @group Nav - More Results
===================================== */

.moreResults {
  border-top: 2px solid #e6e6e6;
  padding-top: 1.5em;
}

/*.moreResults dt {
  float: left;
  margin-right: .5em;
  }*/

.moreResults ul {
  /*float: right;*/
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.moreResults li {
  display: inline;
  padding: 0 0.25em;
  list-style: none;
  border-right: 1px solid #b6b6b6;
}

.moreResults li.resultsFound {
  padding-right: 0.5em;
}

.moreResults li.navNext {
  border-right: 0;
}

.moreResults li.navPrev {
  border-right: 0;
}

.moreResults li a {
  padding: 0;
}

/* @end Nav - More Results
-------------------------------- */

/* @end NAVIGATION/UI
-------------------------------- */

/* @group MODULES
===================================== */

/* @group Galleries
===================================== */

#svGallery {
  border-top: 2px solid #e6e6e6;
  padding-top: 1.8em;
  margin: 0 26px;
}

#svGallery li a {
  border: 0px solid #ccc; /* Add the the total number of pixels added by borders and padding here to includes/display_objects/gallery/htmlhead/gallery.cfm */
}

/* @end
-------------------------------- */

/* @group Calendar
===================================== */

.svCalendar table {
  margin: 0;
  width: 100%;
  border: 1px solid #000;
}

.svCalendar th {
  background: #2f1535;
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
  border: 1px solid #000 !important;
}

#previousMonth a {
  background: url(../assets/img/prevArrow.gif) no-repeat #e5a946 center;
  display: block;
  width: 100%;
  height: 31px;
  text-indent: -9999px;
}
#nextMonth a {
  background: url(../assets/img/nextArrow.gif) no-repeat #e5a946 center;
  display: block;
  width: 93%;
  height: 31px;
  text-indent: -9999px;
}

.svCalendar th a,
.svCalendar th a:hover {
  background: #2f1535;
  color: #e5a946;
}

.svCalendar th a:hover {
  color: #2e7aa7;
}

.svCalendar tr.dayofweek td {
  background: #000;
  color: #fff;
  border: 3px solid #aa34a9;
}

.svCalendar th,
.svCalendar td {
  border: 1px solid #fff;
}

.svCalendar td {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#000', endColorstr='#aa34a9'); /* for IE */
  background: -ms-linear-gradient(top, #000 0%, #aa34a9 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#000), to(#aa34a9)); /* for webkit browsers */
  background: -moz-linear-gradient(top, #000, #aa34a9); /* for firefox 3.6+ */
  border: 1px solid #000;
  color: #aa34a9;
}

.svCalendar td a {
  color: #fff;
}
/* @end Calendar
-------------------------------- */

/* @group Dragable Feeds */

#svRSSFeeds form {
  background: transparent;
}

li.boxItemHeader {
  /* Link of items inside dragable boxes */
  color: #2e7aa7;
  padding-left: 10px;
  background: url(../assets/img/pointer_red_sm.gif) no-repeat left 0.25em;
}

a.boxItemHeader {
  /* Link of items inside dragable boxes */
  color: #2e7aa7;
}

#svAddNewFeed {
  padding: 0 !important;
  background: transparent !important;
}

#svAddNewFeed button {
  /* !important used to override dragable_feeds.css */
  background: #2e7aa7 !important;
  font-size: 11px !important;
}

/* @end */

/* @group Features
===================================== */

#features {
  overflow: hidden;
}

#features dl {
  float: left;
  clear: none;
  width: 213px;
  margin: 0 16px 0 0;
}

#features dt {
  font-size: 2em;
  font-weight: bold;
  letter-spacing: -0.025em;
}

#features dt a {
  color: #232323;
}

#features dt a:hover {
  text-decoration: none;
}

#features dd {
  font-size: 1.1em;
}

#features dd p {
  margin: -3px 0 8px;
  line-height: 15px;
}

#features dd a {
  font-weight: bold;
}

#features dd.image {
  float: none;
  margin: 0 0 1.7em;
  position: relative;
}

#features dd.image img {
  /* recommended size: */
  /*width: 213px;*/
  /*height: 120px;*/
}

/* @end Features
-------------------------------- */

/* @group Home Feeds
===================================== */

.sidebar .svIndex dt.releaseDate {
  font-size: 1em;
  text-transform: uppercase;
  color: #fff !important;
}
.svIndex dt.releaseDate {
  color: #000 !important;
}

/* @end Home Promo Feeds
-------------------------------- */

/* @group Indexes
===================================== */
.svIndex dl {
  padding-bottom: 1em;
  border-bottom: 1px dotted #ccc;
}

.sidebar .svIndex dl {
  margin: 0.5em;
  padding: 0.5em 0 0.5em;
}

.svIndex dl.hasImage {
  /*padding-left: 90px;
    min-height: 90px; Both set dynamically in dsp_feed.cfm & dsp_portal.cfm*/
  position: relative;
}

.sidebar .svIndex dl.hasImage {
  min-height: 0;
  padding-left: 0;
}

.svIndex dl.hasImage .image {
  float: none;
}

.sidebar .svIndex dl.hasImage .image {
  display: none;
}

.content .svIndex {
  margin-top: 2em;
}

.content .svIndex dt {
  font-size: 1.6em;
  margin-bottom: 0.5em;
}

.svIndex dt.releaseDate {
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font-size: 1em !important;
}

.svIndex dd {
  line-height: 1.2;
}

.svIndex dd.image {
  position: absolute;
  top: 0;
  left: 0;
}

/* @group Ratings */

.content .svIndex dd.credits,
.content .svIndex dd.comments,
.content .svIndex dd.tags,
.content .svIndex dd.rating {
  line-height: 20px; /* acommodate height of stars */
}

div.stars a {
  width: 14px;
  height: 13px;
  background: transparent url(../assets/img/rater/stars.png) no-repeat;
}

.zero {
  background-image: url(../assets/img/rater/star_zero.png);
}
.one {
  background-image: url(../assets/img/rater/star_one.png);
}
.onehalf {
  background-image: url(../assets/img/rater/star_onehalf.png);
}
.two {
  background-image: url(../assets/img/rater/star_two.png);
}
.twohalf {
  background-image: url(../assets/img/rater/star_twohalf.png);
}
.three {
  background-image: url(../assets/img/rater/star_three.png);
}
.threehalf {
  background-image: url(../assets/img/rater/star_threehalf.png);
}
.four {
  background-image: url(../assets/img/rater/star_four.png);
}
.fourhalf {
  background-image: url(../assets/img/rater/star_fourhalf.png);
}
.five {
  background-image: url(../assets/img/rater/star_five.png);
}

/* @end */

/* @group Slideshow
===================================== */

.svSlides dl {
  border: 0;
  margin: 0;
}

.svSlides dl.hasImage {
  /* padding-left & min-height set dynamically in /display_ojects/feedslideshow/htmlhead/slideshow.jquery.cfm */
  position: relative;
}

.index .svSlideshow {
  position: relative;
  width: 627px;
  overflow: hidden;
  padding: 30px 30px 10px;
  margin: 0 0 2.2em;
  color: #b4dffa;
  background: #102e50 url(../assets/img/bg-hero.jpg) no-repeat !important;
  border: 0;
}

.index .svSlides {
  position: relative;
  border: 0;
  z-index: 1;
  padding: 0;
  margin: 0 !important;
  /* min-height set dynamically in /display_ojects/feedslideshow/htmlhead/slideshow.jquery.cfm */
}

.index .svSlides dl {
  /* Height set dynamically in /display_ojects/feedslideshow/htmlhead/slideshow.jquery.cfm */
  /*position is set by jquery.slidshow plugin */
  width: auto !important;
  padding-bottom: 0;
  margin-right: 0;
  border: 0;
  font-size: 1em;
  background: transparent !important; /*for IE 6/7*/
}

.index .svSlides dt {
  margin-bottom: 0.35em !important;
  font-size: 2.6em !important;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 1;
  color: #fff;
}

.index .svSlides dt.releaseDate {
  font-size: 11px !important;
  font-weight: normal;
  margin-bottom: 0.5em !important;
}

.index .svSlides dt a {
  color: #fff;
}

.index .svSlides dd {
  margin-bottom: 1.1em;
  letter-spacing: -0.025em;
  font-size: 1.4em !important;
  line-height: 1.2em;
  padding: 0;
}

.index .svSlides dd.image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  float: none;
  padding: 0;
  border: 3px solid #1c5981;
}

.index .svSlides dd.image img {
  display: block;
}

.index .svSlides .readMore {
  float: left;
}

.index .svSlides .readMore a {
  font-size: 11px !important;
  font-weight: bold;
  color: #fff;
  height: 2.1em;
  line-height: 2.1em;
  border: none;
  padding: 0 1em;
  display: block;
  width: 60px;
  text-align: left;
  padding-right: 22px;
  margin-top: 0.75em;
  background: #2e7aa7 url(../assets/img/arrows_white.gif) no-repeat 6.8em 50%;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
}

.svPager {
  text-align: center;
  line-height: 2;
}

.index ol.svPager {
  /* width set dynamically in /display_ojects/feedslideshow/htmlhead/slideshow.jquery.cfm */
  /* width: #pagerWidth#; */
  position: relative;
  z-index: 2;
  text-align: center;
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

ol.svPager li {
}

ol.svPager a {
  color: #fff;
  background: #0c243a;
  outline: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

ol.svPager li.activeLI a,
ol.svPager a:hover {
  background: #ff3405;
  text-decoration: none;
}

/* @end Slideshow
-------------------------------- */

/* @end Indexes
-------------------------------- */

/* @group Session Tools
===================================== */

#svSessionTools p#welcome {
  font-weight: bold;
  color: #ff3405;
}

/* @end Session Tools
-------------------------------- */

/* @group User Tools - Categories
===================================== */

#svCategories li {
  margin: 0;
  padding: 0;
}

#svCategories li a {
  margin: 0;
  padding: 0;
}

/* @end User Tools - Categories
-------------------------------- */

/* @group User Tools - Favorites
===================================== */

#favoriteList li {
  margin: 0;
  padding: 0;
  font-size: 1.1em;
}

#favoriteList li a {
  margin: 0;
  padding: 0;
}

/* @end User Tools - Favorites
-------------------------------- */

/* @end MODULES
-------------------------------- */

/* CUSTOM */

.leftBoxes,
#sysUpcomingEvents {
  padding: 10px;
  margin-bottom: 15px;
  color: #fff !important;
  width: 100%;
  background: #999; /* for non-css3 browsers */
  /* GRADIENT */
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#070707', endColorstr='#666666'); /* for IE */
  background: -ms-linear-gradient(top, #070707 0%, #666 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#070707), to(#666)); /* for webkit browsers */
  background: -moz-linear-gradient(top, #070707, #666); /* for firefox 3.6+ */
  /* TRANSPARENCY */
  -moz-opacity: 0.95;
  -khtml-opacity: 0.95;
  opacity: 0.95;
}

.leftBoxes h4,
#sysUpcomingEvents h3 {
  color: #1d9cff;
  font-size: 26px;
  background: none !important;
  text-transform: none !important;
  margin: 0 0 10px 0;
  padding: 0;
}
.homeBoxes {
  float: left;
  width: 195px;
  display: block;
  margin: 100px 40px 0 0;
}
.connect {
  float: left;
  width: 241px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 26px;
  text-align: right;
  padding-right: 30px;
}
.connectHeader {
  float: right;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 26px;
  text-align: right;
  margin: 3px 0 0 60px;
}
.copyRight {
  text-align: center;
  color: #f7a861;
  font-size: 12px;
}

#merchandise #svAsset img {
  width: 360px;
}
