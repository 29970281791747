/* @override 
  http://localhost:8500/muracms/default/css/reset.css */

/* This file is part of Mura CMS. 

  Mura CMS is free software: you can redistribute it and/or modify 
  it under the terms of the GNU General Public License as published by 
  the Free Software Foundation, Version 2 of the License. 

  Mura CMS is distributed in the hope that it will be useful, 
  but WITHOUT ANY WARRANTY; without even the implied warranty of 
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the 
  GNU General Public License for more details. 

  You should have received a copy of the GNU General Public License 
  along with Mura CMS.  If not, see <http://www.gnu.org/licenses/>. 

Linking Mura CMS statically or dynamically with other modules constitutes the preparation of a derivative work based on 
Mura CMS. Thus, the terms and conditions of the GNU General Public License version 2 ("GPL") cover the entire combined work.

However, as a special exception, the copyright holders of Mura CMS grant you permission to combine Mura CMS with programs
or libraries that are released under the GNU Lesser General Public License version 2.1.

In addition, as a special exception, the copyright holders of Mura CMS grant you permission to combine Mura CMS with 
independent software modules (plugins, themes and bundles), and to distribute these plugins, themes and bundles without 
Mura CMS under the license of your choice, provided that you follow these specific guidelines: 

Your custom code 

• Must not alter any default objects in the Mura CMS database and
• May not alter the default display of the Mura CMS logo within Mura CMS and
• Must not alter any files in the following directories.

 /admin/
 /tasks/
 /config/
 /requirements/mura/
 /Application.cfc
 /index.cfm
 /MuraProxy.cfc

You may copy and distribute Mura CMS with a plug-in, theme or bundle that meets the above guidelines as a combined work 
under the terms of GPL for Mura CMS, provided that you include the source code of that other code when and as the GNU GPL 
requires distribution of source code.

For clarity, if you create a modified version of Mura CMS, you are not obligated to grant this special exception for your 
modified version; it is your choice whether to do so, or to make such modified version available under the GNU General Public License 
version 2 without this exception.  You may, if you choose, apply this exception to your own modified versions of Mura CMS. */

/*  Some styles, ideas, and concepts derived from:

  Eric Meyer Reset - http://meyerweb.com/eric/tools/css/reset/index.html
  BlueprintCSS - http://code.google.com/p/blueprintcss/
  YUI - http://developer.yahoo.com/yui/
*/

/* @group Globals - Text Resets
===================================== */

/*html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  }

body { line-height: 1; }*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

sup, sub {line-height:0;}

a img { border: none; }

/* @end Globals - Text Resets
-------------------------------- */

/* @group Globals - Lists (OL, UL)
===================================== */

ul { margin: 0 0 1em 4em; }

ol { margin-left: 5em; }

p + ul, p + ol { margin-top: -0.5em; } /* get lists below paragraphs closer to the paragraph */

li ul, li ol { margin-top: .5em; margin-bottom: .75em; } /* lists inside of LI's need room to breathe on top and don't need as much space on bottom */

li { margin: 0 0 .5em; }

/* change bullet style when nesting lists */
ul { list-style: disc; }
ul ul { list-style: circle; }
ul ul ul { list-style: square; }
ol { list-style: decimal; }
ol ol, ol.alpha { list-style: lower-alpha; } /* ol.alpha is optional in fckeditor */
ol ol ol { list-style: lower-roman; }

/* @end Globals - Lists (OL, UL)
-------------------------------- */


/* @group Globals - Definition Lists (DL)
===================================== */

dl { 
  clear: both;
  margin-bottom: 1em;
  }

dt {
  margin: 0 0 .5em;
  line-height: 1;
  font-weight: bold;
  }

dt a { text-decoration: none; }

dd {
  margin: 0 0 .5em;
  padding: 0;
  line-height: 1.2;
  }

dd p {
  margin: 0 0 .5em;
  padding: 0;
  }

dd img {}


/* @group Globals - Definition Lists - 2 Column
===================================== */

dl.twoColumn {
  margin: 0;
  text-align: left;
  }

dl.twoColumn dt {
  float: left;
  clear: left;
  width: 33%;
  }

dl.twoColumn dd {
  clear: right;
  margin: 0 0 0 35%;
  padding: 0;
  }

/* @end Globals - Definition Lists - 2 Column
-------------------------------- */

/* @end Globals - Definition Lists (DL)
-------------------------------- */


/* @group Globals - Tables
===================================== */

table { /* tables still need 'cellspacing="0"' in the markup? */
  border-collapse: collapse;
  border-spacing: 0;
  }

caption {
  font-weight: normal;
  text-align: left;
  }

th {
  padding: .5em;
  border: 1px solid;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
  }

td {
  padding: .5em;
  line-height: 1.2;
  vertical-align: top;
  }

/* @end Globals - Tables
-------------------------------- */


/* @group Globals - Forms
===================================== */

form { margin-bottom: 2em; }

form ul,
form ol,
.content form ul,
.content form ol { margin: 0; }

fieldset ol, fieldset ul { padding-top: 15px; }

form li {
  clear: both;
  width: 100%;
  margin-bottom: 1.5em;
  list-style: none;
  }

form li#svCaptcha {
  width: auto;
  margin-left: 27%;
  padding-left: 7px;
  }

form li#svCaptcha img {
  width: auto !important;
  padding: 0 !important;
  border: 0 !important;
  }
  
form #subSettings label {
    float: none;
    font-weight: normal;
    }
    
fieldset {
  clear: both;
  margin: 0 0 2em;
  padding: 0 15px .5em;
  }

legend {
  margin-left: -10px;
  padding: 0 10px;
  font-size: 1.4em;
  line-height: 1.2;
  font-weight: normal;
  }

label,
p.fieldLabel,
form li.mura-form-radio p,
form li.mura-form-checkbox p {
  float: left;
  width: 25%;
  margin-right: 2%;
  padding-right: 7px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right;
  }

form li.mura-form-radio label, form li.mura-form-checkbox label {
  float: none;
  display: block;
  width: 100%;
  margin: 0 0 .8em;
  padding: .2em 0 0;
  font-weight: normal;
  text-align: left;
}

form li.mura-form-checkbox label.checkbox {
  color: red;
  text-align: left;
  width: auto;
  padding-top: .5em;
  margin-left: 28%;
  line-height: 1;
  color: #0c2846;
  }

form li.mura-form-checkbox label.checkbox input.checkbox {
  float: left;
  }

.mura-form-radio div,
.mura-form-checkbox div { 
  width: 70%;
  margin-left: 29%;
  }

.multiInputs {
  float: left;
  width: 65%;
  }

.inputNote {
  font-size: .9em;
  color: #777;
  }

form li span { font-weight: normal; }

fieldset input.text { 
  width: 68%; 
  }

label input {
  margin: 0 .5em 0 0;
}

fieldset textarea {
  width: 68%;
  height: 10em;
  }

form .buttons { text-align: right; }

button, .buttons input { cursor: pointer; }
input[type='submit'] { cursor: pointer; }

/* prevent Fx <= 3.5 from showing hidden inputs when some properties are set. See: Bugzilla id# 436046 & 501959 */
input[type='hidden'] { display: none !important; }

.sidebar form {
  float: left;
  width: 100%;
  margin-bottom: 2em;
  }

.sidebar form li {
  float: none;
  width: auto;
  margin: 0 0 .5em;
  display: block;
  text-align: left;
  }

.sidebar label {
  float: none;
  width: auto;
  margin: 0 0 .25em;
  text-align: left;
  }

.sidebar input.text {
  width: 95%;
  margin: 0;
  }

.required {
  margin: 0 0 .5em .25em;
  font-weight: normal;
  color: #b00;
  }

p.required {
  float: left;
  clear: left;
  margin: 0;
  padding-left: 7px;
  background: url(../images/icon_star.png) no-repeat;
  }

p#loginMsg.required {
  margin-bottom: 2em;
  }

input.first { margin-left: 0; }

.flow label { float: none; width: auto; }




/* @group Globals - Forms - Required Fields
===================================== */

form li.req {} /* could add background color */

.req label { background: url(../images/icon_star.png) no-repeat 100% 0; } /* could add color */

.req ins {
  position: absolute;
  left: -9999px;
  }

li .req {
  padding-right: 7px;
  background: url(../images/icon_star.png) no-repeat 100% 0;
  }

.req input {} /* could do { border: 1px solid darkred; color: darkred; } */


/* @end Globals - Forms - Required Fields
-------------------------------- */

/* @end Globals - Forms
-------------------------------- */
